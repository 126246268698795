import { useMemo } from "react";
import { NORNE_CENTERID } from "@portal/assets/banks";
import { IUseCenterIdOpenPages } from "./types";
import { useSearchParams } from "next/navigation";
import useCenterId from "../useCenterId";

const useCenterIdOpenPages: IUseCenterIdOpenPages = () => {
  const searchParams = useSearchParams();
  const sessionCenterId = useCenterId();

  const centerIdCalc = useMemo(() => {
    let centerId = sessionCenterId ?? (searchParams?.get("centerId") as string) ?? (searchParams?.get("centerid") as string);
    if (!centerId) centerId = NORNE_CENTERID;
    return centerId;
  }, [sessionCenterId, searchParams]);

  return centerIdCalc;
};

export default useCenterIdOpenPages;
