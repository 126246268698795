export const GetBankColorTheme = (centerId?: string) => {
  if (centerId === "0000") return "theme_norne";
  if (centerId === "3411") return "theme_fanaSparebank";
  if (centerId === "9090") return "theme_eika";
  if (centerId === "3910") return "theme_sparebankenMøre";
  if (centerId === "3890") return "theme_sparebankenSognOgFjordane";
  if (centerId === "9820") return "theme_obosBank";
  if (centerId === "3601") return "theme_sparebankenVest";
  if (centerId === "2220") return "theme_sparebankenØst";
  if (centerId === "4484") return "theme_aasenSparebank";
  if (centerId === "4589") return "theme_sekstiAatteGraderNord";
  if (centerId === "4730") return "theme_sekstiAatteGraderNord";
  if (centerId === "4605") return "theme_sekstiAatteGraderNord";

  return "theme_default";
};
