"use client";

import LayoutNotAuthorized from "@portal/components/organisms/layouts/Layout-NotAuthorized";
import PortalPageBox from "@portal/components/atoms/portalPageBox";
import Typography from "@portal/components/atoms/typography";
import { ButtonComponent } from "@portal/components/atoms/buttons/main/ButtonComponent";
import { TextLine } from "@portal/components/atoms/textLine";
import { TextResources } from "@portal/assets/text/TextResources";
import { useRouter } from "next/navigation";
import Icon from "@portal/components/atoms/icons";
import { Suspense, useEffect } from "react";
import { GetBankColorTheme } from "@portal/helpers/getBankColorTheme";
import PageSpinner from "@portal/components/organisms/pageSpinner";
import useCenterIdOpenPages from "@portal/hooks/useCenterIdOpenPages";

const NotFoundPage = () => {
  const centerId = useCenterIdOpenPages();
  const router = useRouter();

  useEffect(() => {
    const theme = GetBankColorTheme(centerId);
    document.body.classList.add(theme);
  });

  return (
    <LayoutNotAuthorized centerId={centerId}>
      <PortalPageBox>
        <div className="flex flex-col justify-center items-center space-y-3">
          <Icon name="questionMarkCircleIcon" size="xxxlarge" />
          <div>
            <Typography variant="H6">{TextResources.NOT_FOUND_PAGE_TITLE}</Typography>
            <TextLine />
          </div>
          <Typography variant="Body">{TextResources.NOT_FOUND_PAGE_TEXT}</Typography>
          <div className="mt-5">
            <ButtonComponent
              text={TextResources.NOT_FOUND_PAGE_GO_HOME}
              wide
              onClick={async () => {
                router.push("/");
              }}
            />
          </div>
        </div>
      </PortalPageBox>
    </LayoutNotAuthorized>
  );
};

const NotFoundPageWrapper = () => (
  <Suspense fallback={<PageSpinner />}>
    <NotFoundPage />
  </Suspense>
);

export default NotFoundPageWrapper;
